<template>
  <a-modal
    :visible="visible"
    :title="$t('inscription.downloadTitle')"
    @cancel="closeModal"
  >
    <p>{{ $t("menu.listeActivites") }}</p>
    <a-select
      mode="multiple"
      show-search
      :placeholder="$t('paiement.listeActivites')"
      style="width: 100%"
      option-filter-prop="children"
      :filter-option="filterOption"
      v-model="selectedValues"
    >
      <a-select-option
        v-for="activtiy in activities"
        :key="activtiy._id"
        :value="activtiy._id"
      >
        {{ activtiy.designation }}
      </a-select-option>
    </a-select>
    <template #footer>
      <a-button key="back" @click="closeModal">{{
        $t("action.annuler")
      }}</a-button>
      <a-button
        key="submit"
        type="primary"
        :disabled="selectedValues.length === 0"
        :loading="loading"
        @click="download()"
      >
        {{ $t("action.imprimer") }}</a-button
      >
    </template>
  </a-modal>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";

export default {
  data() {
    return {
      activities: [],
      classList: [],
      selectedActivities: [],
      selectedValues: [],
      loading: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: mapState(["settings"]),
  async created() {
    apiClient
      .post("/activities/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            designation: 1,
          },
        },
      })
      .then((res) => {
        this.activities = res.data;
      })
      .catch((e) => this.$message.error(this.$t("error.aucActivite")));
    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
          },
        },
      })
      .then((res) => (this.classList = res.data))
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async handleActivities() {
      const promises = this.selectedValues.map((activityId) => {
        return apiClient
          .get("/payment/etatImpaye/activity/" + activityId)
          .then((res) => {
            const activity = this.activities.find((a) => a._id === activityId);
            return {
              activityId: activityId,
              activityName: activity && activity.designation,
              students: res.data.map((student) => ({
                studentId: student.studentId,
                studentName: student.student,
                classroom: student.classroom,
              })),
            };
          })
          .catch((e) => {
            console.log(e);
            return null;
          });
      });

      this.selectedActivities = (await Promise.all(promises)).filter(Boolean);
    },
    closeModal() {
      this.loading = false;
      this.selectedActivities = [];
      this.selectedValues = [];
      this.$emit("close");
    },
    async download() {
      this.loading = true;
      try {
        await this.handleActivities();
        // Filter out activities with no students
        const activitiesWithStudents = this.selectedActivities.filter(
          (activity) => activity.students && activity.students.length > 0
        );

        const emptyActivities = this.selectedActivities.filter(
          (activity) => !activity.students || activity.students.length === 0
        );

        if (activitiesWithStudents.length > 0) {
          // If there are some activities with students but also some empty ones
          if (emptyActivities.length > 0) {
            const emptyActivityNames = emptyActivities
              .map((activity) => activity.activityName)
              .join(", ");
            this.$message.warning(
              this.$t("inscription.noStudentInActivity") +
                `: ${emptyActivityNames}`,
              5
            );
          }
          this.generatePDF(activitiesWithStudents);
        } else {
          this.loading = false;
          // If all selected activities are empty
          this.$message.warning(this.$t("inscription.unableToGeneratePdf"), 5);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    pdfHeader(doc, subTitle) {
      const title = "Liste des élèves inscrits à l'activité";
      let arrayOfImages = this.settings.image;
      let imgData = new Image();

      for (let i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          imgData.src = arrayOfImages[i].logo;
          break;
        }
      }

      doc.setFont("Amiri");

      const date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      // Center the title
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.setFontSize(15);

      // Calculate center position for title
      const titleWidth = doc.getTextDimensions(title).w;
      const titleX = (pageWidth - titleWidth) / 2;
      doc.text(title, titleX, 22);

      // Center and add subtitle if provided
      if (subTitle) {
        doc.setFontSize(15);
        doc.setFont("Amiri", "Bold");
        const subTitleWidth = doc.getTextDimensions(subTitle).w;
        const subTitleX = (pageWidth - subTitleWidth) / 2;
        doc.text(subTitle, subTitleX, 30);
      }
      doc.setFont("Amiri", "normal");
      // Add logo and date
      if (imgData.src) {
        doc.addImage(imgData, "JPEG", 15, 5, 20, 20);
      }

      doc.setFontSize(8);
      doc.text(date.toLocaleDateString("fr-FR", options), 170, 15);
    },
    generatePDF(activities) {
      const doc = new JsPDF();
      const margin = 15;
      const pageWidth = doc.internal.pageSize.width;
      const tableWidth = pageWidth - 2 * margin;
      let totalPages = 0;

      activities.forEach((activity, activityIndex) => {
        // Add new page for subsequent activities
        if (activityIndex > 0) {
          doc.addPage();
        }
        // Create header for each activity
        this.pdfHeader(doc, activity.activityName);
        // Process data to include classroom names
        const processedData = activity.students.map((student) => {
          const classInfo = this.classList.find(
            (c) => c._id === student.classroom
          ) || { name: "--" };
          return {
            ...student,
            classroom: classInfo.name,
            level: classInfo.level,
          };
        });

        // Sort by classroom level and name
        processedData.sort((a, b) => {
          if (a.level < b.level) return -1;
          if (a.level > b.level) return 1;
          if (a.classroom < b.classroom) return -1;
          if (a.classroom > b.classroom) return 1;
          return 0;
        });

        const tableData = processedData.map((student, index) => ({
          num: index + 1,
          student: student.studentName,
          classroom: student.classroom,
        }));

        // Table configuration
        const tableColumns = [
          { header: "N°", dataKey: "num" },
          {
            header: "Nom complet",
            dataKey: "student",
            styles: { fontStyle: "bold" },
          },
          { header: "Classe", dataKey: "classroom" },
        ];

        const headerColor = [75, 124, 243];
        const alternateColor = [240, 244, 255];
        const borderColor = [240, 240, 240];

        // Generate table
        doc.autoTable({
          startY: 35,
          theme: "plain",
          columns: tableColumns,
          body: tableData,
          styles: {
            font: "Amiri",
            cellPadding: 2,
          },
          margin: { left: margin, right: margin, top: 20, bottom: 30 },
          headStyles: {
            fillColor: headerColor,
            textColor: [255, 255, 255],
            fontSize: 11,
            halign: "center",
            valign: "middle",
            cellPadding: 4,
            lineWidth: 0.1,
            lineColor: borderColor,
          },
          bodyStyles: {
            fontSize: 9,
            halign: "left",
            valign: "middle",
            cellPadding: 3,
            lineColor: borderColor,
            lineWidth: 0.1,
          },
          alternateRowStyles: {
            fillColor: [255, 255, 255],
          },
          columnStyles: {
            0: {
              cellWidth: tableWidth * 0.08,
              halign: "center",
              fontStyle: "bold",
            },
            1: {
              cellWidth: tableWidth * 0.52,
              halign: "left",
            },
            2: {
              cellWidth: tableWidth * 0.4,
              halign: "center",
              fontSize: 10,
              fillColor: alternateColor,
              fontStyle: "bold",
            },
          },
        });
      });

      // Get total number of pages
      totalPages = doc.internal.getNumberOfPages();

      // Now add page numbers to all pages
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        const text = `Page ${i} / ${totalPages}`;
        doc.setFontSize(10);

        // Calculate center position
        const textWidth = doc.getTextDimensions(text).w;
        const textX = (doc.internal.pageSize.width - textWidth) / 2;

        doc.text(text, textX, doc.internal.pageSize.height - 10);
      }

      this.closeModal();
      doc.save(`Liste_activites.pdf`);
    },
  },
};
</script>
