<template>
  <div class="card">
    <div
      class="card-header"
      style="display: flex; justify-content: space-between"
    >
      <!-- add  activityDRAWER -->
      <a-drawer
        :title="$t('inscription.nouvInscri')"
        :width="720"
        :visible="inscriptinActDrawer"
        :body-style="{ paddingBottom: '80px' }"
        @close="onClose"
      >
        <activity-multi-ins-form
          @updateActivityInscription="updateActivities"
        ></activity-multi-ins-form>
      </a-drawer>
      <a-button type="primary" icon="plus" @click="startInsDrawer">
        {{ $t("inscription.nouvInscri") }}
      </a-button>
      <!-- Download Modal Student List By Activity-->
      <download-by-activity
        :visible="downloadModalVisible"
        @close="downloadModalVisible = false"
      ></download-by-activity>
      <a-button icon="file-pdf" @click="downloadModalVisible = true">
        {{ $t("action.imprimer") }}
      </a-button>
    </div>

    <div class="card-body">
      <a-table
        :loading="tableLeading"
        :data-source="actvitiyPayments"
        :columns="columns"
        :pagination="true"
        rowKey="_id"
      >
        <template slot="expandedRowRender" slot-scope="record">
          <div style="margin: 0">
            <div v-for="(fee, key) in record.fees" :key="key" class="d-inline">
              <a-tag :color="fee.isPayed ? 'green' : 'red'">
                <b v-if="fee.isPayed"
                  >{{ formatFeeName(fee.feeName) }} {{ $t("paiement.paye") }}</b
                >
                <b v-else
                  >{{ formatFeeName(fee.feeName) }}
                  {{ $t("paiement.impaye") }}</b
                >
              </a-tag>
            </div>
          </div>
        </template>
        <template slot="student" slot-scope="text, record">
          <router-link
            :to="`/eleve/${record.studentId}`"
            class="kit__utils__link"
            >{{ record.student }}</router-link
          >
        </template>
        <template slot="mode" slot-scope="text">
          <a-tag color="#3498db" v-if="text == 'M'"> Paiement mensuel </a-tag>
          <a-tag color="#27ae60" v-else> Paiement par tranche </a-tag>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag :color="record.status == 'payed' ? 'green' : 'red'">
            <b v-if="record.status == 'payed'">{{ $t("profil.payes") }}</b>
            <b v-else> {{ $t("profil.nonPayes") }}</b>
          </a-tag>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            :title="$t('paiement.annulerInscri')"
            @confirm="() => onDelete(record)"
          >
            <a-button type="danger">
              <a-icon type="delete" href="javascript:;" />{{
                $t("action.annuler")
              }}
            </a-button>
          </a-popconfirm>
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import store from "store";
import moment from "moment";
import activityMultiInsForm from "./activityMultiInsForm";
import downloadByActivity from "./downloadByActivity.vue";
import { formatFeeName } from "@/utilities/settings";
import { pdfHeader } from "@/utilities/pdfUtils";

const frMonths = {
  1: "Janvier ",
  2: "Février ",
  3: "Mars ",
  4: "Avril ",
  5: "Mai ",
  6: "Juin ",
  7: "Juillet ",
  8: "Août ",
  9: "Septembre ",
  10: "Octobre ",
  11: "November ",
  12: "Décembre ",
};
const getStatus = (fees) => {
  if (fees.find((f) => !f.isPayed)) return "impayed";
  else {
    return "payed";
  }
};
export default {
  computed: mapState(["settings", "user"]),
  components: { activityMultiInsForm, downloadByActivity },
  async created() {
    this.tableLeading = true;
    this.form = this.$form.createForm(this, { name: "addActivityForm" });

    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    const classPromise = apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classList = res.data))
      .catch((e) => this.$message.error("Aucunne classe est trouvé"));
    const paymentPromise = this.getActivitiesPayments();

    await Promise.all([classPromise, paymentPromise]);
  },
  data() {
    return {
      reductionType: "dinar",
      frMonths,
      loadingClasse: false,
      columns: [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "student",
          key: "student",
          scopedSlots: {
            customRender: "student",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.student
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("accueil.activite"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("emploi.classe"),
          dataIndex: "classroom",
          key: "classroom",
          scopedSlots: {
            customRender: "classroom",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.classroom
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "Mode de paiement",
          dataIndex: "mode",
          key: "mode",
          width: "150px",
          scopedSlots: {
            customRender: "mode",
          },
          filters: [
            { text: "Mensuelle", value: "M" },
            { text: "Par tranche", value: "T" },
          ],

          onFilter: (value, record) => record.mode.indexOf(value) === 0,
        },
        {
          title: "État global",
          dataIndex: "status",
          key: "status",
          scopedSlots: {
            customRender: "status",
          },
          filters: [
            { text: "Payé", value: "payed" },
            { text: "Non payé", value: "impayed" },
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
        },

        {
          title: this.$t("recette.annulation"),
          dataIndex: "supprimer",
          scopedSlots: { customRender: "operation" },
          //fixed: "right",
        },
      ],

      classList: [],
      actvitiyPayments: [],
      loading: false,
      tableLeading: true,
      searchInput: null,
      inscriptinActDrawer: false,
      downloadModalVisible: false,
    };
  },
  methods: {
    formatFeeName,
    startInsDrawer() {
      this.inscriptinActDrawer = true;
    },
    onClose() {
      this.inscriptinActDrawer = false;
    },
    moment,
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onDelete(key) {
      const { _id } = key;
      apiClient
        .patch("/payment/" + _id, {
          data: {
            status: "inactive",
          },
        })
        .then((r) => {
          this.$message.success(this.$t("success.inscriSupp"));
          const dataSource = [...this.actvitiyPayments];
          this.actvitiyPayments = dataSource.filter((item) => item._id !== _id);
        })
        .catch((r) => {
          this.$message.error(this.$t("error.impoInscriSupp"));
        });
    },
    updateActivities() {
      this.inscriptinActDrawer = false;
      this.getActivitiesPayments();
    },
    async getActivitiesPayments() {
      this.tableLeading = true;
      return apiClient
        .get("/payment/etatImpaye/activity/all")
        .then((res) => {
          this.actvitiyPayments = res.data.map((p) => ({
            ...p,
            classroom:
              this.classList.find((c) => c._id == p.classroom)?.name ?? "--",
            status: getStatus(p.fees),
          }));
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.aucInscri"));
        })
        .finally(() => {
          this.tableLeading = false;
        });
    },
  },
};
</script>
